@tailwind base;
@tailwind components;
@tailwind utilities;

.nav{
    @apply border border-green-500 rounded-md p-1 hover:bg-green-400 hover:text-white shadow-md cursor-pointer 
}

.input{
    @apply border border-orange-300 bg-green-100 rounded-lg font-semibold p-1 w-60
}

.submit-button{
    @apply border border-red-500 rounded-full px-3 hover:bg-red-400 cursor-pointer hover:text-white hover:border hover:border-green-500 shadow-lg transition ease-out duration-300 transform hover:scale-125 font-semibold
}

.map{position: absolute; 
    top:0; 
    bottom:0; 
    left:0; 
    right:0;
}

/* body{
    background: rgb(36,161,254);
background: linear-gradient(180deg, rgba(36,161,254,1) 24%, rgba(138,99,56,1) 29%);
width:100%;
  height:100vh;
background-size: cover;
    background-repeat: no-repeat;
} */

/* Leaflet */
.leaflet-container{
    width: 100vw;
    height: 100vh;
}


  
.leaflet-popup-content-wrapper {
    background-color: black !important;
    color: white !important;
    border-radius: 12px !important; /* Adjust as needed */
  }
  
  .leaflet-popup-tip-container {
    display: none !important; /* Hide the default popup tip */
  }
  
  .popup-content {
    font-size: 18px; /* Adjust as needed */
  }
  