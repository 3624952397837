@import url('https://fonts.googleapis.com/css2?family=Signika&display=swap');

.font-signika {
    font-family: 'Signika', sans-serif;
  }


html {
	scroll-padding-top: 100px;
}

.book-now-button {
	position: absolute;
	top: 10px;
	left: 10px;
	background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black, now 50% opacity */
	color: white;
	border: none;
	border-radius: 50px;
	padding: 10px 20px;
	cursor: pointer;
	transition: background-color 0.3s;
  }
  
  .book-now-button:hover {
	background-color: rgba(255, 255, 255, 0.5); /* semi-transparent white, now 50% opacity */
	color: black;
  }
  

  .image-container {
	width: 100%;
	padding-bottom: 75%; /* Adjust this value to change the aspect ratio */
	position: relative;
  }
  
  .image-container img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
  }
  
  .pop-out {
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
  }
  